import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    //
    // {
    //     path: '/',
    //     name: 'login',
    //     component: LoginView
    //
    // },
    {
        path: '/',
        name: 'home',
        component: Home
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
