<template>

  <div>

      <v-card style="position: absolute; top: 16px; right: 32px; z-index: 99; width: 120px; "
              class="d-flex align-center"
              elevation="0"
              color="#000a" @click="showDeviceIdDialog=true" v-if="showEditBtn">
        <v-btn icon>
          <v-avatar size="26"><img src="@/../public/img/logo.png" alt="Edit"/></v-avatar>
        </v-btn>

        <div
            class="text-body-2 " style="color: white"
        >
          Aaden
          <span class="font-weight-black mr-1">POS</span>
        </div>
      </v-card>

<!--      <v-btn icon style="position: absolute; top: 16px; right: 32px; z-index: 99" v-if="showEditBtn">-->
<!--        <v-avatar @click="showDeviceIdDialog=true"><img src="@/../public/img/icon.png" alt="Edit"/></v-avatar>-->
<!--      </v-btn>-->

      <div style="font-size: 20px;" class="d-flex flex-row flex-grow-0">
        <template v-if="carouselArrLength <= 0">
          <v-img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                 lazy-src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                 :style="{'width': showRight ? 'calc(100vw - 460px)': '100vw'}"
                 height="100vh"></v-img>
        </template>
        <template v-else>
          <div class="d-flex">
            <v-carousel
                cycle
                hide-delimiter-background
                show-arrows-on-hover
                height="100vh"
                :style="{'width': showRight ? 'calc(100vw - 460px)': '100vw'}"
            >
              <v-carousel-item
                  v-for="(slide,i) in carouselArr"
                  :key="'slid'+i"
                  cover
                  :src="slide"
              />
            </v-carousel>
          </div>
        </template>

        <template v-if="showRight">
          <CartList/>
        </template>

      </div>



    <v-dialog v-model="showDeviceIdDialog" width="40vw">
      <v-card class="pa-6 overflow-y-auto" style="height: 40vh">
        <div style="position: absolute; top: 20px; right: 20px">
          <v-btn @click="saveDeviceId" color="primary" rounded class="elevation-0">{{ $t('save') }}</v-btn>
        </div>
        <h2 class="d-flex justify-start mx-2"> {{ $t('setting') }} </h2>

        <v-divider class="my-2"/>

        <div class="d-flex align-center justify-space-between px-2">
          <div style="width: 150px">
            <div>
              {{ $t('deviceId') }}
            </div>
            <div class="text-caption">{{ $t('machineId') }}</div>
          </div>

          <div style="width: 120px" class="d-flex flex-row align-baseline">
            <div class="mr-1">IK</div>
            <v-text-field
                class="my-4 rounded"
                v-model="deviceId"
                dense
                rounded
                outlined
                @keyup.enter.prevent="saveDeviceId"
                persistent-hint
                type="number"
            />
          </div>

        </div>


        <!--        后续功能-->
        <!--        <div class="d-flex align-center justify-space-between px-2">-->
        <!--          <span style="width: 150px">隐藏菜品编号</span>-->
        <!--          <v-switch v-model="config.hideDishCode" hide-details dense/>-->
        <!--        </div>-->

        <!--        <div class="d-flex align-center justify-space-between px-2">-->
        <!--          <span style="width: 150px">零元的菜品不显示</span>-->
        <!--          <v-switch v-model="config.hideDishPriceIsZero" hide-details dense/>-->
        <!--        </div>-->


        <div class="d-flex align-center justify-space-between px-2">
          <span style="width: 150px">{{ $t('hiddenEditBtn') }}</span>
          <v-switch v-model="showEditBtn" hide-details dense/>
        </div>


      </v-card>

    </v-dialog>
    <v-dialog v-model="showBetragDialog" width="40vw">
      <v-card class="py-4">
        <div class="d-flex justify-center mt-6">
          <h2>Gesamt Betrag:</h2>
        </div>

        <div class="d-flex justify-center align-baseline pb-6">
          <strong style="font-size: 40px">{{ totalOrderPrice | priceDisplay }}</strong>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="qrDialog" max-width="600px">
      <v-card class="pa-4">
        <div class="d-flex align-center">
          <div class="text-h5">
            <span>{{ $t('electronicCode') }}</span>
          </div>
          <v-spacer/>
          <div>
            <v-btn
                icon
                @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex flex-column justify-center align-center mt-8">
          <v-img
              :aspect-ratio="1"
              :src="'https://api.qrserver.com/v1/create-qr-code/?size=260x260&data='+ baseUrl + uuidCode"
              width="196px"
          />
          <div class="mt-8">{{ uuidCode }}</div>
        </div>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>

import {
  firebasePath,
  FirestorageDB,
  onSnapshotCartInFirebase, onSnapshotCheckoutCodeInFirebase,
  onSnapshotOrderInFirebase, onSnapshotPointCodeInFirebase,
  onSnapshotShowDisplayStatusInFirebase, onSnapshotUuidInFirebase, setUuidInFirebase
} from "@/views/Components/Login/login-firebase";
import {range} from "lodash";
import {Remember} from '@/GlobalSettings'
import {doc, setDoc} from "firebase/firestore";
import {forceGetSystemSetting, getBatchImgUrl, getCarsousels} from "@/assets/api";
import CartList from "@/views/Components/CartList";
import i18n from "@/i18n";

export default {
  name: 'HomeView',
  components: {CartList},
  // components: {LoginView, CartList},


  data() {
    return {
      baseUrl: location.protocol + `//baobao.aaden.io/%23/?uuid=`,
      showLoginPage: false,
      pointCode: '',
      uuidCode: '',
      checkoutCode: [],
      docOrderList: [],
      docCartList: [],
      showBetrag: false,
      deviceId: Remember.deviceId,
      showDeviceIdDialog: false,
      carouselArr: [],
      showEditBtn: false,
      uuid: null,

      config: Remember
    }
  },

  watch: {
    qrDialog(val){
      if (val) {
        setTimeout(async () => {
          await setUuidInFirebase(this.deviceId)
        },30000)
      }
    },
  },
  methods: {
    async closeDialog() {
      await setUuidInFirebase(this.deviceId)
    },
    getUUIDEvent(val) {
      this.uuid = val
      if (this.uuid) {
        this.showLoginPage = false
      }
      console.log('uuid y', this.uuid)
    },
    range,
    getUuid(val) {
      this.uuidCode = val ?? ''
    },
    getPointCode(val) {
      this.pointCode = val ?? ''
    },
    getCheckoutCode(val) {
      this.checkoutCode = val ?? []
    },
    getDocOrderList(val) {
      this.docOrderList = val
    },
    getDocCartList(val) {
      this.docCartList = val
    },
    getDocShowDialog(val) {
      this.showBetrag = val
    },
    async saveDeviceId() {
      this.showDeviceIdDialog = false
      const data = {deviceId: this.deviceId, order: this.orderDishList, cart: this.cartDishList}
      await setDoc(doc(FirestorageDB, firebasePath, this.deviceId.toString()), data)
      console.log('deviceID', this.deviceId)

      await this.checkDeviceID()
      Remember.deviceId = this.deviceId
      await this.setOrderListInFirebase({})
      // window.location.reload()
    },
    async setOrderListInFirebase(data) {
      await setDoc(doc(FirestorageDB, firebasePath, this.deviceId.toString()), data)
    },

    async getBatchCarouselUrlArr() {

      const imgArr = (await getCarsousels(this.deviceId)).content?.map(i => i.image) ?? []
      console.log(imgArr,'arr')

      return await getBatchImgUrl(this.deviceId, imgArr)

    },

    async checkDeviceID() {

      try {
        if (this.deviceId > 0) {

          this.carouselArr = (await this.getBatchCarouselUrlArr())
          console.log('carouselArr', this.carouselArr)
          await onSnapshotOrderInFirebase(this.getDocOrderList, this.deviceId)
          await onSnapshotCartInFirebase(this.getDocCartList, this.deviceId)
          await onSnapshotShowDisplayStatusInFirebase(this.getDocShowDialog, this.deviceId)
          await onSnapshotCheckoutCodeInFirebase(this.getCheckoutCode, this.deviceId)
          await onSnapshotPointCodeInFirebase(this.getPointCode,this.deviceId)
          await onSnapshotUuidInFirebase(this.getUuid, this.deviceId)

          const currentLangs = await forceGetSystemSetting({
            section: 'language',
            sKey: 'langs',
            sValue: 'de',
            defaultValue: 'de',
            sType: 'string',
            minimumVersion: '1.7.784',
            sOptions: '',
            tagList: 'basic'
          })
          if (typeof currentLangs === 'string') {
            Remember.localLangs = currentLangs
          }
          i18n.locale = Remember.localLangs
        }

      } catch (e) {
        console.log(e)
      }
    }

  },
  computed: {
    qrDialog() {
      return !this.showBetrag && this.uuidCode;
    },
    carouselArrLength() {
      return this.carouselArr.length
    },
    showRight() {
      return this.cartDishListLength > 0 || this.orderDishListLength > 0
    },
    showBetragDialog() {
      return this.showBetrag
    },
    orderDishList() {
      return Object.values(this.docOrderList ?? []).map(item => {
        item.hasDiscount = parseFloat(item.tempDiscountMod) < 0 || item.tempDiscountStr !== '';
        item.hasAppend = item.aName || item.agName || item.priceInfo
        return item
      })
    },
    orderDishListLength() {
      return this.orderDishList.length ?? 0
    },
    cartDishList() {
      return Object.values(this.docCartList ?? [])
    },
    cartDishListLength() {
      return this.cartDishList.length ?? 0
    },
    totalOrderPrice() {
      const realList = this.orderDishList.filter(it => this.checkoutCode.includes(it.code)) ?? []
      const res = realList.map(i => parseFloat(i.realPrice * i.sumCount)).reduce((a, c) => a + c, 0)
      return res.toFixed(2)
    },
    totalCartPrice() {
      const res = this.cartDishList.map(i => parseFloat(i.realPrice * i.sumCount)).reduce((a, c) => a + c, 0)
      return res.toFixed(2)
    },
    orderDishListSize() {
      return this.orderDishList.length
    },
    cartHasDishes() {
      return this.cartDishList.length > 0
    },


  },

  async mounted() {
    this.showEditBtn = true
    this.showDeviceIdDialog = false
    await this.checkDeviceID()
  }
}
</script>

<style>
.full-size {
  position: absolute;
  top: 0;
  background-color: whitesmoke;
  bottom: 0;
  right: 0;
  left: 0;
}

::-webkit-scrollbar {
  display: none;
}

.border-class {
  border-bottom: lightgrey 3px solid;
}


</style>
