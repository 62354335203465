import hillo from "hillo";
import {Remember} from "@/GlobalSettings";

export function getNgrokUrl(deviceId) {
    console.log(location.protocol, 'protocol')
    return location.protocol +  '//ik' + ('' + deviceId).padStart(4, '0') + '.ngrok.aaden.io'
}

export async function getCarsousels(deviceId) {
    const url = getNgrokUrl(deviceId) + '/PHP/Restaurant.php?op=showAllRestaurantImage'
    return await hillo.get(url)
}

export function getImgUrl(deviceId, imgName) {
    return getNgrokUrl(deviceId) + '/Resource/dishImg/' + imgName
}

export function getBatchImgUrl(deviceId, imgNameArr) {
    return imgNameArr.map(imgName => {
        return getImgUrl(deviceId, imgName)
    })
}

export async function forceGetSystemSetting (item) {
    return (await hillo.post(getNgrokUrl(Remember.deviceId) + '/PHP/Restaurant.php?op=forceGetSystemSetting', {
        systemSetting: JSON.stringify(item)
    })).content
}
