import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import _ from 'lodash'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.filter('priceDisplay', function (price) {
  price = price ?? 0
  let d = price
  if (price.includes && price.includes(',') && price?.includes('.')) {
    d = price.replace('.', '').replace(',', '.')
  }
  return _.round(parseFloat(d), 2).toFixed(2).replace('.', ',') + ' €'
})


async function initial () {

  new Vue({
    i18n,
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')
}

initial()
