import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    signOut
} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import {doc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCtvQ3d-HAtHTUg_-505c-qXRnlz8RlZeg',
    authDomain: 'aaden-saas.firebaseapp.com',
    projectId: 'aaden-saas',
    storageBucket: 'aaden-saas.appspot.com',
    messagingSenderId: '169167876904',
    appId: '1:169167876904:web:b83934e5a34d1cbfcc161d',
    measurementId: 'G-QRPH7NLDZS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const FirestorageDB = getFirestore(app)
const auth = getAuth()
export const firebasePath = 'customerDisplay'

export function signIn(auth, provider) {
    if (isMobile()) {
        return signInWithRedirect(auth, provider)
    } else {
        return signInWithPopup(auth, provider)
    }
}

export async function loginWithGoogle() {
    const provider = new GoogleAuthProvider()
    return await signIn(auth, provider)

}

export async function loginWithApple() {
    const provider = new OAuthProvider('apple.com');
    return await signIn(auth, provider)
}

export async function createAccount(email, password) {
    return await createUserWithEmailAndPassword(auth, email, password)
}

export async function loginWithEmailAndPass(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
}

export async function logOut() {
    return await signOut(auth)
}

export async function restPassword(email) {
    return await sendPasswordResetEmail(auth, email)
}

export async function checkAuthState() {
    await onAuthStateChanged(auth, (user) => {
        if (user) {
            return user
        } else {
            return null
        }
    })
}

export function isMobile() {
    let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return mobile != null
}

export async function onSnapshotOrderInFirebase(callBack, deviceId) {
    const orderPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, orderPathName), (doc) => {
        callBack(doc.data()?.order)
    })
}

export async function onSnapshotCartInFirebase(callBack, deviceId) {
    const cartPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, cartPathName), (doc) => {
        callBack(doc.data()?.cart)
    })
}

export async function onSnapshotShowDisplayStatusInFirebase(callBack, deviceId) {
    const checkOutStatusPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, checkOutStatusPathName), (doc) => {
        callBack(doc.data()?.showBetrag)
    })
}

export async function onSnapshotCheckoutCodeInFirebase(callBack, deviceId) {
    const checkOutStatusPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, checkOutStatusPathName), (doc) => {
        callBack(doc.data()?.checkoutCode)
    })
}

export async function onSnapshotPointCodeInFirebase(callBack, deviceId) {
    const checkOutStatusPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, checkOutStatusPathName), (doc) => {
        callBack(doc.data()?.pointCode)
    })
}

export async function onSnapshotUuidInFirebase(callBack, deviceId) {
    const checkOutStatusPathName = deviceId.toString()
    await onSnapshot(doc(FirestorageDB, firebasePath, checkOutStatusPathName), (doc) => {
        callBack(doc.data()?.uuid)
    })
}

export async function setUuidInFirebase (deviceId) {
    const data = { uuid: '' }
    try {
        await updateDoc(doc(FirestorageDB, firebasePath, deviceId.toString()), data)
    } catch (e) {
        console.log(e)
    }
}

export async function setPointCodeInFirebase (deviceId) {
    const data = { pointCode: '' }
    try {
        await updateDoc(doc(FirestorageDB, firebasePath, deviceId.toString()), data)
    } catch (e) {
        console.log(e)
    }
}
