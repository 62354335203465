<template>
  <div style="border-left: lightgrey 3px solid; width: 480px">
    <div style="height: 10vh; border-bottom: darkgrey 2px solid; "
         class="d-flex justify-space-between align-center px-6">
      <strong>Aaden Pos V0.1.2</strong>
    </div>

    <template>
      <!--Order Dish List-->
      <div style="height: calc(100vh - 23vh);  overflow-y: scroll; " class="pt-4 overflow-x-hidden "
           :style="{'background-color': cartHasDishes ? 'darkgrey': 'white'} "
      >
        <div v-for="(item,index) in orderDishList" :key="'order'+item.code+index" class="mb-3">
          <div style="display: grid; grid-template-columns: 40px 1fr 180px; justify-content: space-around">
            <div style="color: blue; font-size: large" class="d-flex justify-end font-weight-bold">
              {{ item.sumCount }} x
            </div>

            <div style="margin-left: 18px"><span style="font-weight: bold">{{ item.code }}.</span>{{ item.name }}</div>

            <div
                style="font-size: 16px; font-weight: bold; "
                class="d-flex flex-row justify-end align-center mx-5">
                    <span style="font-size: 10px;text-decoration: line-through;" class="mr-3"
                          v-if="item.hasDiscount">
                      {{ item.originPrice | priceDisplay }}
                    </span>
              <div class="d-flex justify-end align-start">{{ item.realPrice | priceDisplay }}</div>
            </div>
          </div>


          <template v-if="item.hasAppend">
            <div v-for="index in range(item.aNameArr?.length)" :key="'orderAppend'+index"
                 style="display: grid; grid-template-columns: 1fr 72px; font-size: 14px" class="ml-15">
              <div>{{ item.agNameArr[index] }} {{ item.aNameArr[index] }}</div>
              <div v-if="parseFloat(item.priceInfoArr[index]) != 0.0"> {{ parseFloat(item.priceInfoArr[index]) | priceDisplay}}</div>
            </div>
          </template>

        </div>

      </div>

    </template>

    <template v-if="cartHasDishes">
      <!--Cart Dish List-->
      <div style="max-height: calc(80vh - 10vh);
              overflow-y: scroll; overflow-x:hidden;
              position: absolute;
              background-color: white;
              width: 456px;
              bottom: 12vh ; border: black 2px solid" class="my-2">
        <div class="ml-3">{{ $t('newDishInCart') }}</div>

        <div v-for="(item,index) in cartDishList" :key="'cart'+item.code+index">
          <div
              style="display: grid; grid-template-columns: 40px 1fr 100px; justify-content: space-around; margin-top: 16px">
            <div style="display: flex; justify-content: end;">
              {{ item.sumCount }} x
            </div>
            <div style="margin-left: 16px"><span style="font-weight: bold">{{ item.name }}</span></div>

            <div
                style="font-size: 14px; margin-right: 16px" class="d-flex flex-row justify-end ">
              <span>{{ item.realPrice | priceDisplay }}</span>
            </div>
          </div>

          <template v-if="item.hasAppend">
            <div v-for="index in range(item.aNameArr?.length)" :key="'cartAppend'+index"
                 style="font-size: 14px" class="d-flex flex-row justify-space-between px-7">
              <div>{{ item.aNameArr[index] }}</div>
              <div v-if="parseFloat(item.priceInfoArr[index]) != 0.0"> {{ parseFloat(item.priceInfoArr[index]) | priceDisplay}}</div>
            </div>
          </template>

        </div>

      </div>
    </template>

    <div class="d-flex justify-space-between px-4"
         style="height: 12vh; border-top: darkgrey 2px solid; ; z-index: 99; ">
      <div>
        <div style="font-size: 20px">Posten:</div>
        <h4>{{ orderDishListSize }}</h4>
      </div>

      <div style="width: 220px">
        <div style="font-size: 18px" class="d-flex justify-end">Gesamt Betrag:</div>

        <template v-if="parseFloat(totalCartPrice)>0">
          <div class="d-flex justify-space-between">
            <div>
              <h5 class="d-flex justify-end">{{ totalOrderPrice }} €</h5>
              <h5 class="d-flex justify-end"> + {{ totalCartPrice }} €</h5>
            </div>
            <v-spacer/>
            <strong style="font-size: 32px" class="d-flex justify-end">
              {{ parseFloat(totalOrderPrice) + parseFloat(totalCartPrice) | priceDisplay }} </strong>
          </div>
        </template>
        <template v-else>
          <h1 class="d-flex justify-end">{{ totalOrderPrice | priceDisplay }} </h1>
        </template>
      </div>


    </div>

  </div>
</template>

<script>

import {
  firebasePath,
  FirestorageDB,
  onSnapshotCartInFirebase,
  onSnapshotOrderInFirebase,
  onSnapshotShowDisplayStatusInFirebase,
} from "@/views/Components/Login/login-firebase";
import {range} from "lodash";
import {Remember} from '@/GlobalSettings'
import {doc, updateDoc} from "firebase/firestore";
import {getBatchImgUrl, getCarsousels} from "@/assets/api";

export default {
  props: {},
  name: 'CartList',
  data() {
    return {
      showLoginPage: false,
      showLogin: true,
      docOrderList: [],
      docCartList: [],
      showDialogStatus: {showCart: false, showBetrag: false},
      deviceId: Remember.deviceId,
      showDeviceIdDialog: false,
      carouselArr: [],
      showEditBtn: false,
    }
  },

  methods: {
    range,
    getDocOrderList(val) {
      this.docOrderList = val
    },
    getDocCartList(val) {
      this.docCartList = val
    },
    getDocShowDialog(val) {
      this.showDialogStatus = val
    },
    async saveDeviceId() {
      this.showDeviceIdDialog = false
      Remember.deviceId = this.deviceId
      // await this.setOrderListInFirebase({})
      window.location.reload()
    },
    async setOrderListInFirebase(orderList) {
      const data = {order: orderList}
      await updateDoc(doc(FirestorageDB, firebasePath, this.deviceId.toString()), data)
    },
    async getBatchCarouselUrlArr() {

      const imgArr = (await getCarsousels(this.deviceId)).content?.map(i => i.image) ?? []
      return getBatchImgUrl(this.deviceId, imgArr)

    },
    hideEditBtn() {
      this.showEditBtn = false
      this.showDeviceIdDialog = false
    },

  },
  computed: {

    showRight() {
      const res = this.cartDishListLength > 0 || this.orderDishListLength > 0
      this.$emit('input', res)
      return res
    },
    showBetragDialog() {
      const res = this.showDialogStatus?.showBetrag || false
      return res
    },
    orderDishList() {
      const res = Object.values(this.docOrderList ?? []).map(item => {
        item.hasDiscount = parseFloat(item.tempDiscountMod) < 0 || item.tempDiscountStr !== '';
        item.hasAppend = item.aName || item.agName || item.priceInfo
        return item
      })

      res.sort(function (a, b) {
        return (a.code).localeCompare(b.code)
      })
      return res
    },
    orderDishListLength() {
      return this.orderDishList.length ?? 0
    },
    cartDishList() {
      return Object.values(this.docCartList ?? [])
    },
    cartDishListLength() {
      return this.cartDishList.length ?? 0
    },
    totalOrderPrice() {
      const res = this.orderDishList.map(i => parseFloat(i.realPrice * i.sumCount)).reduce((a, c) => a + c, 0)
      return res.toFixed(2)
    },
    totalCartPrice() {
      const res = this.cartDishList.map(i => parseFloat(i.realPrice * i.sumCount)).reduce((a, c) => a + c, 0)
      return res.toFixed(2)
    },
    orderDishListSize() {
      return this.orderDishList.length
    },
    cartHasDishes() {
      return this.cartDishList.length > 0
    }


  },

  async mounted() {

    this.showEditBtn = true
    this.showDeviceIdDialog = false


    try {
      if (this.deviceId > 0) {
        this.carouselArr = (await this.getBatchCarouselUrlArr())
        await onSnapshotOrderInFirebase(this.getDocOrderList, this.deviceId)
        await onSnapshotCartInFirebase(this.getDocCartList, this.deviceId)
        await onSnapshotShowDisplayStatusInFirebase(this.getDocShowDialog, this.deviceId)
      } else {
        this.showDeviceIdDialog = true
      }

    } catch (e) {
      console.log(e)
    }

  }
}
</script>

<style scoped>

</style>
